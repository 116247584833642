$custom-file-text: (
    en: "Browse",
    fr: "Parcourir",
);

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~datatables.net-bs4/css/dataTables.bootstrap4";

.login-img {
    background-image: url("../../public/img/planoo-login.jpg");
    background-size: auto;
}

body {
    background-color: #fff;
    font-family: "Roboto";
    font-size: 13px;
}

.body-appli {
    background-color: #efeff1;
}

.invalid-feedback {
    font-size: 100%;
}
.form-control {
    font-size: 13px;
}

.form-group {
    margin-bottom: 0.5rem;
}

.login-form {
    background-color: #fff;
    padding: 30px 30px 30px 30px;
    border-radius: 4px;
    margin: 40px 15px 15px 15px;
}

.login-form > .form-control {
    margin-bottom: 10px;
}

.btn-perso {
    font-size: 13px;
    color: #fff !important;
    text-align: center;
    border-radius: 3px;
    background-color: #00796b;
    border: 2px solid #00796b;
}

.btn-small {
    padding: 0.15rem 0.5rem !important;
}

.btn-action {
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.logo {
    max-height: 60px;
}
.under-navbar {
    padding-top: 95px;
}

/* datatable */
div.dataTables_processing {
    top: 0% !important;
    margin-top: -50px !important;
}

table.dataTable {
    border-collapse: collapse !important;
    background-color: #fff;
}

.dataTable th {
    background-color: #4db6ac;
    color: #fff;
}

.box {
    margin: 10px 0;
    padding: 10px 0 10px;
    border: 1px solid #dadce0;
    background-color: white;
    border-radius: 4px;
}

.box-content {
    padding: 0 25px;
    display: flex;
    line-height: 2rem;
}
.box-text {
    display: block;
}

.box-icon {
    width: 44px;
    font-size: 16px;
    text-align: center;
    color: #7d7d7e;
}

.box-label {
    min-width: 150px;
    text-align: right;
    padding-right: 8px;
    color: #7d7d7e;
}

.box-line {
    color: #7d7d7e;
}

.btn-perso-info {
    color: #fff;
    font-size: 13px;
    background-color: #909ba2;
    text-align: center;
    border-radius: 3px;
    border: 2px solid #909ba2;
    padding: 0.25rem 0.5rem !important;
}

.btn-perso-alert {
    background-color: red;
    border-color: red;
}

.btn-small-font {
    font-size: 13px !important;
}

.btn-encadre {
    border: 1px solid #6363b1;
    padding: 0.25rem 0.5rem !important;
    border-radius: 4px;
    margin-left: 5px;
    line-height: normal;
}

.btn-statut {
    color: #fff;
    font-size: 13px;
    text-align: center;
    border-radius: 3px;
    padding: 0.25rem 0.5rem !important;
}

.flashbag {
    border: 2px solid #c3e6cb;
    color: #155724;
    background-color: #d4edda;
    font-weight: 500;
    margin-top: 20px;
    padding: 7px 15px;
    border-radius: 4px;
    text-align: left;
}

/* avant user-inactive */
.zonecadre {
    border: 1px solid #dadce0;
    background-color: #fff;
    border-radius: 4px;
    padding: 12px 12px 0;
    margin-bottom: 10px;
}

.user-nonreferent {
    background-color: #a93226;
    border: 1px solid #a93226;
}

.user-externe {
    background-color: #7d3c98;
    border: 1px solid #7d3c98;
}

.user-inactif {
    background-color: #808896;
    border: 1px solid #808896;
}

.user-admin {
    background-color: #2874a6;
    border: 1px solid #2874a6;
}

.form-check {
    display: inline !important;
    padding-right: 10px;
}

#calendrier td {
    vertical-align: middle;
    background-color: #fff;
}

.font_today {
    font-weight: 600;
    border: 2px solid red;
    border-radius: 50%;
}
.color_evt {
    background-color: #149485 !important;
}

.color_suiteevt {
    background-color: #18c0ad !important;
}

.color_evtalerte {
    background-color: #cb1747 !important;
}

.font_evt {
    font-weight: 600;
    color: #fff;
}

.font_suiteevt {
    color: #fff;
}

.color_horsmoiscourant {
    background-color: #d4d4db !important;
}
.color_vacance {
    background-color: #d6f7f3 !important;
}
.color_ferie {
    background-color: #37e4cf !important;
}
#calendrier tr {
    height: 50px;
}

.calendrier-mois {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #4db6ac !important;
}

.calendrier-mois a {
    color: #fff;
}

.participation-btn {
    font-size: 13px;
}
.participation-convoque {
    background-color: #a1887f;
    border-color: #a1887f;
}
.participation-absent {
    background-color: #607d8b;
    border-color: #607d8b;
}
.participation-present {
    background-color: #558b2f;
    border-color: #558b2f;
}
.participation-dispense {
    background-color: #d7ccc8;
    border-color: #d7ccc8;
}

.participation-liste {
    background-color: #efeff1;
    padding: 10px;
}

.filtre-actif {
    color: #fff;
    border: 2px solid #28a745;
    background-color: #28a745;
}

.jumbotron {
    background-color: #fff;
}

.annuaire .box-content {
    line-height: 1.6rem;
}
.annuaire .box-title {
    padding-top: 0;
    padding-bottom: 0;
}
.annuaire {
    padding-top: 5px;
}

/*  Evenement */
.btn-typeevenement {
    background-color: #006064;
    border-color: #006064;
}

.evt-bloc {
    background-color: #fff;
    margin-bottom: 10px;
}
.evt-bloc-liste {
    min-height: 250px;
}
.evt-bloc-entete {
    margin-bottom: 10px;
    color: #fff;
    background-color: var(--couleurevt, #006064);
}
.evt-bloc-img {
    min-height: 130px;
}
.evt-bloc-titre {
    color: #fff;
    display: flex;
    min-height: 60px;
}
.evt-titre {
    text-transform: uppercase;
    padding: 10px 10px 10px 20px !important;
    font-weight: 800;
    font-size: 16px;
    max-width: 270px;
}
.evt-bloc-content {
    font-weight: 500;
    padding: 2px 0 2px 15px;
    color: #7d7d7e;
    display: flex;
}
.evt-bloc-content-plusspace {
    padding: 5px 0 5px 15px !important;
}
.evt-bloc-libelle {
    padding-left: 25px;
    padding-bottom: 10px;
    color: #7d7d7e;
}
.evt-box-icon {
    width: 30px;
}
.hr-light {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.evt-bloc-titre > span > a {
    color: #fff;
}
.evt-bloc-titre > span > .btn-encadre {
    border: 1px solid #fff;
}

// Blog
.article-bloc {
    background-color: #fff;
    margin-bottom: 10px;
}
.article-bloc-liste {
    min-height: 300px;
}
.article-bloc-entete {
    margin-bottom: 10px;
    color: #fff;
    min-height: 100px;
    background-color: #638475;
}
.article-bloc-texte {
    min-height: 120px;
}
.article-liresuite {
    padding: 4px 6px;
    border: 1.5px solid #1b89d6;
    border-radius: 2px;
    color: #1b89d6;
    font-weight: bold;
}
.article-bloc-img {
    min-height: 20vh;
    background-position: center center;
    background-repeat: no-repeat;
}
.article-bloc-footer {
    padding-top: 10px !important;
    min-height: 50px;
}
.article-bloc-titre {
    color: #fff;
    display: flex;
}
.article-titre {
    text-transform: uppercase;
    padding: 10px 10px 10px 20px !important;
    font-weight: 800;
    font-size: 16px;
    max-width: 270px;
}
.article-bloc-entete > div > .btn-encadre {
    border: 1px solid #fff !important;
}
.article-bloc-entete > div > a {
    color: #fff !important;
}

.btn-blogcategorie {
    background-color: #ff9800;
    border-color: #ff9800;
}
.article-bloc-content {
    font-weight: 500;
    padding: 2px 0 2px 15px;
    color: #7d7d7e;
}

.annuaire-bloc {
    background-color: #fff;
    padding-bottom: 10px;
}
.annuaire-bloc-liste {
    margin-bottom: 10px;
}
.annuaire-bloc-entete {
    margin-bottom: 0px;
    color: #fff;
    background-color: #4db6ac;
}
.annuaire-bloc-titre {
    color: #fff;
    min-height: 50px;
    text-align: center;    
    display: flex;
}

.annuaire-titre-entreprise {
    text-transform: uppercase;
    padding: 5px !important;
    font-weight: 700;
    font-size: 14px;
	color: #fff;
    padding: 5px 10px 10px 10px !important;
}

.annuaire-titre {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
    max-width: 270px;
    padding: 10px 20px 10px 10px !important;
}
.annuaire-content {
    background-color: #fff;
    min-height: 140px;
    padding-bottom: 0px;
}
.annuaire-content-pro {
    background-color: #fff;
    min-height: 120px;
    border-top: 1px solid #4db6ac;
    padding-top: 10px;
}
.annuaire-categorie {
    min-height: 60px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.annuaire-infos {
    display: flex;
    padding: 3px 10px 3px 0;
}

.annuaire-infos-label {
    min-width: 110px;
    text-align: right;
    padding-right: 10px;
    color: #7d7d7e;
    font-size: 12px;
}
.annuaire-infos-text {
    font-weight: bold;
    color: #263238;
}

//Dashboard
.widget-box {
    display: block;
    min-height: 75px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 15px;
    color: #fff;
}
.widget-icon {
    float: left;
    height: 60px;
    width: 60px;
    text-align: center;
    font-size: 27px;
    line-height: 65px;
    background: #4db6ac;
}
.widget-titre {
    padding: 10px;
    margin-left: 60px;
    height: 60px;
    font-weight: bold;
    font-size: 16px;
    background-color: #fff;
    color: #4e555b;
    border-top: 2px solid #4db6ac;
    border-bottom: 1px solid #bdbdbd;
    text-transform: uppercase;
    display: flex;
}
.widget-content {
    color: #4e555b;
    padding: 10px 10px 10px 30px;
    height: 32vh;
    overflow: auto;
}
.widget-more a {
    color: #fff;
}

.showuser-entete {
    margin-bottom: 10px;
    color: #fff;
    background-color: #4db6ac;
    display: flex;
}
.showuser-titre {
    text-transform: uppercase;
    padding: 10px 10px 10px 20px !important;
    font-weight: 800;
    font-size: 18px;
    background-color: #4db6ac;
}

.showuser-entete > div > a {
    color: #fff;
}

.avatar-navbar {
    max-height: 45px;
    border-radius: 50%;
}

.avatar-news {
    max-width: 100%;
    max-height: 90px;
    border-radius: 50%;
}

.avatar-annuaire {
    max-width: 90%;
    max-height: 135px;
    border-radius: 50%;
}

/*  Pagger */
.page-item.active .page-link {
    background-color: #00796b;
    border-color: #00796b;
}

.page-link {
    color: #00796b;
}

.page-link:hover {
    color: #00796b;
}

@media 
only screen and (max-width: 500px) {
    .pagination {
          font-size: 12px;
    }

    .btn-small-font {
        font-size: 12px !important;
    }
}